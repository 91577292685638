import React from "react"
import Layout from "../components/Layout"
import { StaticQuery, graphql } from 'gatsby'
import Landing from '../route/Landing/index'
import ContactForm from 'component/ContactForm'

const Hello = (t) => {
  return (
    <h1>contact!</h1>
  )
}

export default function Home({ location, blok }) {
  return (
    <Layout location={location} t="default">
      {/* <ContactForm t="default" /> */}
    </Layout>

  )
}